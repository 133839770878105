<template>
  <div class="classify">
    <Header ref="pagedetail"></Header>
    <main class="layout hide-aside content-inner">
      <div id="page">
        <div class="tag-cloud-title is-center">
          分类 - <span class="tag-cloud-amount">{{ classify.length }}</span>
        </div>
        <div class="tag-cloud-list is-center" v-loading="loading">
          <a
            ref="classifies"
            v-for="(item, index) in classify"
            :key="index"
            @click="go(item.classify)"
          >
            {{ item.classify }}
          </a>
        </div>
      </div>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "Classify",
  data() {
    return {
      classify: "",
      pagetagResult: "",
      loading: false,
    };
  },
  components: {
    Header,
    Footer,
    ToTop,
  },
  methods: {
    getClassify() {
      this.loading = true;
      this.$axios.get("/api/getclassify").then((response) => {
        this.classify = response.data;
        this.loading = false;
      });
    },
    go(pagetag) {
      this.$axios
        .get("/api/searchpages", {
          params: { searchKey: pagetag },
        })
        .then((response) => {
          this.pagetagResult = response.data;
          let pagetitle = pagetag.replace(/\s*/g, "");
          this.$router.push(`/classifylist/title=${pagetitle}`);
          localStorage.setItem(
            "blogpagelists",
            JSON.stringify([this.pagetagResult, pagetag])
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    setpagedetail() {
      this.$refs.pagedetail.$refs.pagedetail.innerText = "分类";
    },
  },
  mounted() {
    this.getClassify();
    this.setpagedetail();
  },
};
</script>
<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/3.png");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
}
.layout.hide-aside {
  max-width: 1000px;
}
#page {
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
  width: 100%;
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
.tag-cloud-title,
.tag-cloud-amount {
  font-size: 2.5rem;
}
.tag-cloud-list {
  user-select: none;
}
.tag-cloud-list a {
  display: inline-block;
  padding: 0 0.4rem;
  transition: all 0.3s;
}
.tag-cloud-list a:hover {
  color: #49b1f5 !important;
  transform: scale(1.1);
}
.tag-cloud-list a:nth-child(2n-1) {
  font-size: 1.2em;
  color: rgb(45, 101, 122);
}
.tag-cloud-list a:nth-child(2n) {
  font-size: 1.4em;
  color: rgb(117, 36, 7);
}
.tag-cloud-list a:nth-child(3n) {
  font-size: 2.1em;
  color: rgb(174, 140, 68);
}
.tag-cloud-list a:nth-child(3n-1) {
  font-size: 1.6em;
  color: rgb(87, 198, 173);
}
.tag-cloud-list a:nth-child(4n) {
  font-size: 1.8em;
  color: rgb(116, 82, 182);
}
.tag-cloud-list a:nth-child(4n-1) {
  font-size: 1.7em;
  color: rgb(178, 139, 180);
}
.tag-cloud-list a:nth-child(5n) {
  font-size: 1.3em;
  color: rgb(93, 27, 122);
}
.tag-cloud-list a:nth-child(5n-1) {
  font-size: 1.5em;
  color: rgb(27, 2, 48);
}
</style>